<template>
  <v-menu :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <custom-v-text-field
          v-model="formattedDate"
          :bg-color="background"
          :class="{ active: isActive }"
          :label="labelSlot == 'label' ? label : undefined"
          :prefix="labelSlot == 'prefix' ? prefix : undefined"
          :required="required"
          :rules="rules"
          :variant="variant"

          density="compact"
          flat
          v-bind="props"
      >
        <template v-slot:append-inner>
          <v-icon>mdi-calendar</v-icon>
        </template>
      </custom-v-text-field>
    </template>

    <vc-date-picker
        v-model="localDate"
        class="mt-1"

    ></vc-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";

export default {
  name: "DatePicker",
  emits: ["setDate"],
  props: {
    date: {
      type: [Date, String, Object],
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "outlined",
    },
    labelSlot: {
      type: String,
      default: "label",
    },
  },
  computed: {
    localDate: {
      set(localDate) {
        this.setDate(localDate);
      },
      get() {
        return this.convertDate(this.date);
      },
    },
    formattedDate() {
      return !this.localDate
          ? null
          : moment(this.localDate).format("YYYY-MM-DD");
    },
    prefix() {
      return this.isActive ? `${this.label}: ` : this.label;
    },
    background() {
      if (this.variant !== "solo-filled") return "white";
      return this.isActive ? "primary" : "grey-lighten-5";
    },
    isActive() {
      return this.formattedDate != null;
    },
  },
  methods: {
    convertDate(date) {
      return !date ? null : moment(date).toDate()
    },
    setDate(date) {
      this.$emit("setDate", date);
    },
  },
};
</script>

<style scoped>
.active .custom-v-text-field__prefix {
  color: white;
}
</style>
