import moment from "moment/moment";

export const dateUtils = {
  convertToLocalDate,
  convertToLocalDateTime,
  getMinDate,
  getDateFormattedDayMonthFullYear,
  isBefore,
  isAfter,
};

function convertToLocalDate(item) {
  if (!item) return "";

  return moment(item).format("YYYY-MM-DD");
}

//Convert to DD/MM/YYYY HH:mm:ss
function convertToLocalDateTime(item) {
  if (!item) return "";

  return moment(item).format("YYYY-MM-DD HH:mm:ss");
}

function getMinDate(firstDate, secondDate) {
  if (firstDate === null) return secondDate;
  if (secondDate === null) return firstDate;
  let first = new Date(firstDate);
  let second = new Date(secondDate);
  return first < second ? first : second;
}

function getDateFormattedDayMonthFullYear(string) {
  return moment(string).format("DD/MM/YYYY");
}

function isBefore(date1, date2) {
  date1 = new Date(date1);
  date2 = new Date(date2);
  
  return date1 < date2;
}

function isAfter(date1, date2) {
  date1 = new Date(date1);
  date2 = new Date(date2);

  return date1 > date2;
}
