import router from "@/router/index";
import {authHeader} from "@/helpers/auth-header";
//import store from "@/store/index";

//console.debug("store", store)

const resourceUrl = process.env.VUE_APP_BACKEND_URL +"/loggings"

export const commons = {
    handleJsonResponse,
    handleStringResponse,
    handleResponseWithError,
    uiBackendUrl
};


function uiBackendUrl() {
    return process.env.VUE_APP_BACKEND_URL;
}

function checkResponse(response) {

    try {
        //DEV-5
        if (response.status >= 400 && response.status !== 401) {
            const requestOptions = {
                method: "POST",
                headers: authHeader(),
                body: JSON.stringify({
                    url: response.url,
                    correlationId: response.headers.get("x-correlation-id"),
                    responseStatus: response.status,
                    httpMethod: null, //TODO we need the method
                    isFrontendError: true
                }),
            };
            return fetch(resourceUrl, requestOptions);
        }
    }catch (e) {
        console.error("Error while logging", e)
    }


    if (response.status === 401) {
        localStorage.removeItem('authToken')
        router.push('/');
    }


}

function handleJsonResponse(response) {
    checkResponse(response);

    if (response.ok) {
        return response.text().then((text) => {
            if (text) {
                return text && JSON.parse(text);
            }
        });
    } else {
        return response.text().then((text) => {
            const error = text || response.statusText;
            return Promise.reject(error);
        });
    }
}

function handleStringResponse(response) {
    checkResponse(response);

    if (response.ok) {
        return response.text().then((text) => {
            return text;
        });
    } else {
        return response.text().then((text) => {
            const error = text || response.statusText;
            return Promise.reject(error);
        });
    }
}

function handleResponseWithError(response) {
    return response.text().then((text) => {
        const data = text && tryParseJson(text);
        if (!response.ok) {
            checkResponse(response);
            const error = (data && data.message) || text;
            return Promise.reject(error);
        }
        return data;
    });
}

function tryParseJson(text) {
    try {
        return JSON.parse(text);
    } catch (e) {
        return text;
    }
}
