import store from "@/store/index.js";

export const permissionsData = {
    getDrawerItems,
    getRouterItems,
    checkAuth,
    checkIsEditing,
    checkPermission,
    exploreChildren,
    firstPageToLand
};

function checkIsEditing(next) {
    let isEditing = store.getters["edit/getIsEditing"];
    if (isEditing) {
        store.commit("edit/setConfirmExitDialog", {
            title: "Confirm exit",
            text: "Data not saved, proceed without saving?",
            submit: () => {
                store.commit("edit/resetConfirmExitDialog");
                store.commit("edit/setEditing", false);
                next();
            },
            cancel: () => store.commit("edit/resetConfirmExitDialog"),
        });
        return true;
    }
}

const permissions = [
    {
        path: "/:pathMatch(.*)*",
        name: "unknownRoute",
        component: () => import("@/views/404Page.vue"),
    },
    {
        path: "/",
        name: "login",
        component: () => import("@/views/LoginPage.vue"),
    },
    {
        path: "/signin",
        name: "landing",
        component: () => import("@/views/LandingPage.vue"),
    },
    {
        path: "/userNotFound",
        name: "usernotfound",
        component: () => import("@/views/UserNotFound.vue"),
    },
    {
        path: "/app",
        name: "main",
        component: () => import("@/views/MainPage.vue"),
        children: [
            {
                path: ":tenantId/dashboard",
                name: "dashboard",
                component: () => import("@/views/DashboardPage.vue"),
                showInDrawer: true,
                icon: "app",
                text: "Dashboard",
                meta: {
                    authorization: ["DASHBOARD_MENU"],
                }
            },
            {
                path: ":tenantId/catalogs",
                name: "catalogs",
                component: () => import("@/views/CatalogsPage.vue"),
                showInDrawer: true,
                icon: "book-open",
                text: "Catalogs",
                meta: {
                    authorization: ["CATALOG_MENU"],
                }
            },
            {
                path: ":tenantId/catalogs/:id",
                name: "catalogsEdit",
                component: () => import("@/views/CatalogsEditPage.vue"),
                meta: {
                    authorization: ["CATALOG_MENU"],
                }
            },
            {
                path: ":tenantId/purchaseOrders",
                name: "purchaseOrders",
                component: () => import("@/views/PurchaseOrdersPage.vue"),
                showInDrawer: true,
                icon: "shopping-bag",
                text: "Purchase orders",
                meta: {
                    authorization: ["PURCHASE_ORDER_MENU"],
                }
            },
            {
                path: ":tenantId/purchaseOrders/:id",
                name: "purchaseOrdersEdit",
                props: true,
                component: () => import("@/views/PurchaseOrdersEditPage.vue"),
                meta: {
                    authorization: ["PURCHASE_ORDER_MENU"],
                },
            },
            {
                path: ":tenantId/shipmentRequests",
                name: "shipmentRequests",
                component: () => import("@/views/ShipmentRequestsPage.vue"),
                showInDrawer: true,
                icon: "code-branch",
                text: "Shipment requests",
                meta: {
                    authorization: ["SHIPMENT_REQUEST_MENU"],
                },
            },
            {
                path: ":tenantId/shipmentRequests/:id",
                name: "shipmentRequestsEdit",
                component: () => import("@/views/ShipmentRequestEditPage.vue"),
                meta: {
                    authorization: ["SHIPMENT_REQUEST_MENU"],
                },
            },
            // {
            //     path: ":tenantId/shipmentRequestConsolidation",
            //     name: "shipmentRequestConsolidation",
            //     component: () => import("@/views/ShipmentRequestConsolidationPage.vue"),
            //     showInDrawer: true,
            //     icon: "mdi-consolidate",
            //     text: "Shipment request consolidation",
            // },
            {
                path: ":tenantId/shipmentRequestConsolidation/:id",
                name: "shipmentRequestConsolidationEdit",
                props: true,
                component: () => import("@/views/ShipmentRequestConsolidationEdit.vue"),
                meta: {
                     authorization: ["SHIPMENT_REQUEST_MENU"],
                },
            },
            {
                path: ":tenantId/shipmentOrders",
                name: "shipmentOrders",
                component: () => import("@/views/ShipmentOrderPage.vue"),
                showInDrawer: true,
                icon: "calendar",
                text: "Shipment orders",
                meta: {
                    authorization: ["SHIPMENT_ORDER_MENU"],
                },
            },
            {
                path: ":tenantId/shipmentOrders/:id",
                name: "shipmentOrdersEdit",
                props: true,
                component: () => import("@/views/ShipmentOrderEdit.vue"),
                meta: {
                    authorization: ["SHIPMENT_ORDER_MENU"],
                },
            },
            {
                path: ":tenantId/shipments",
                name: "shipments",
                component: () => import("@/views/ShipmentsPage.vue"),
                showInDrawer: true,
                icon: "create-dashboard",
                text: "Shipments",
                meta: {
                    authorization: ["SHIPMENT_MENU"],
                },
            },
            {
                path: ":tenantId/shipments/:id",
                name: "shipmentsEdit",
                component: () => import("@/views/ShipmentsEditPage.vue"),
                meta: {
                    authorization: ["SHIPMENT_MENU"],
                },
            },
            {
                path: ":tenantId/tracking",
                name: "tracking",
                component: () => import("@/views/TrackingPage.vue"),
                showInDrawer: true,
                icon: "history",
                text: "Tracking",
                meta: {
                    authorization: ["TRACKING_MENU"],
                },
            },
            {
                path: ":tenantId/messages",
                name: "messages",
                component: () => import("@/views/MessagesPage.vue"),
                showInDrawer: true,
                icon: "message",
                text: "Messages",
                meta: {
                    authorization: [
                        "PURCHASE_ORDER_MENU",
                        "SHIPMENT_REQUEST_MENU",
                        "SHIPMENT_ORDER_MENU",
                        "SHIPMENT_MENU",
                    ],
                    roles: ["PLATFORM_ADMIN", "CONTROL_TOWER"],
                },
            },
            {
                path: ":tenantId/messages/:id",
                name: "messagesEdit",
                component: () => import("@/views/ThreadMessagesPage.vue"),
                meta: {
                    authorization: [
                        "PURCHASE_ORDER_MENU",
                        "SHIPMENT_REQUEST_MENU",
                        "SHIPMENT_ORDER_MENU",
                        "SHIPMENT_MENU",
                    ],
                    roles: ["PLATFORM_ADMIN", "CONTROL_TOWER"]
                },
            },
            /*
                  {
                      path: ":tenantId/supplierInvoice",
                      name: "supplierInvoice",
                      showInDrawer: true,
                      icon: "mdi-receipt-text",
                      text: "Invoice Costs",
                      authorization: ["SUPPLIER_INVOICE_MENU"],
                      component: () => import("@/views/SupplierInvoicePage.vue"),
                  },
                   */

            {
                path: ":tenantId/reports",
                name: "reports",
                component: () => import("@/views/ReportsPage.vue"),
                showInDrawer: true,
                icon: "chart-line",
                text: "Reports",
                meta: {
                    authorization: ["REPORTS_MENU"],
                },
            },
            {
                path: ":tenantId/monitorEdi",
                name: "monitorEdi",
                component: () => import("@/views/MonitorEdiPage.vue"),
                showInDrawer: true,
                icon: "monitor-eye",
                text: "Monitor EDI",
                meta: {
                    roles: ["PLATFORM_ADMIN", "CONTROL_TOWER"],
                },
            },
            {
                path: ":tenantId/businessPartners",
                name: "businessPartners",
                component: () => import("@/views/BusinessPartnersPage.vue"),
                showInDrawer: true,
                icon: "handshake",
                text: "Business Partners",
                meta: {
                    roles: ["PLATFORM_ADMIN", "CONTROL_TOWER"],
                },
            },
            {
                path: ":tenantId/businessPartners/:id",
                name: "businessPartnersEdit",
                component: () => import("@/views/BusinessPartnerEditPage.vue"),
                meta: {
                    roles: ["PLATFORM_ADMIN", "CONTROL_TOWER"],
                },
            },
            {
                path: ":tenantId/guides",
                name: "guides",
                component: () => import("@/views/ManualsAndSupportPage.vue"),
                showInDrawer: true,
                icon: "mdi-account-file-outline",
                text: "Manuals & Support",
            },
            {
                text: "Administration",
                showInDrawer: true,
                meta: {
                    authorization: ["SETTINGS"],
                    roles: ["PLATFORM_ADMIN", "CONTROL_TOWER"],
                },
                // class: 'mt-10',
            },
            {
                path: "settings",
                name: "settings",
                component: () => import("@/views/SettingsPage.vue"),
                showInDrawer: true,
                icon: "settings",
                text: "Settings",
                meta: {
                    authorization: ["SETTINGS"],
                    roles: ["PLATFORM_ADMIN", "CONTROL_TOWER"],
                },
                props: true,
            },
            {
                path: "configuration",
                name: "configuration",
                component: () => import("@/views/SettingsPage.vue"),
                showInDrawer: false,
                //TODO Complete
                icon: "mdi-application-cog-outline",
                text: "Platform Setting",
                meta: {
                    authorization: ["SETTINGS"],
                    roles: ["PLATFORM_ADMIN", "CONTROL_TOWER"],
                },
                props: true,
                children: [],
            },
            {
                path: "companies",
                name: "companies",
                component: () => import("@/views/SettingsPage.vue"),
                props: true,
                meta: {
                    authorization: ["SETTINGS"],
                    roles: ["PLATFORM_ADMIN", "CONTROL_TOWER"],
                },
            },
            {
                path: "companies/:id",
                name: "companiesEdit",
                component: () => import("@/views/settings/CustomerEditPage.vue"),
                meta: {
                    authorization: ["SETTINGS"],
                    roles: ["PLATFORM_ADMIN", "CONTROL_TOWER"],
                },
            },
            {
                path: "iso-types",
                name: "ISO Types",
                component: () => import("@/views/settings/ISOTypesPage.vue"),
                meta: {
                    authorization: ["SETTINGS"],
                    roles: ["PLATFORM_ADMIN", "CONTROL_TOWER"],
                },
            },
            {
                path: "locode",
                name: "locode",
                component: () => import("@/views/settings/CodedLocationsPage.vue"),
                meta: {
                    authorization: ["SETTINGS"],
                    roles: ["PLATFORM_ADMIN", "CONTROL_TOWER"],
                },
            },
            {
                path: "groups",
                name: "groups",
                component: () => import("@/views/settings/GroupsPage.vue"),
                meta: {
                    authorization: ["SETTINGS"],
                    roles: ["PLATFORM_ADMIN", "CONTROL_TOWER"],
                },
            },
            {
                path: "groups/:id",
                name: "groupsEdit",
                component: () => import("@/views/settings/GroupsEditPage.vue"),
                meta: {
                    authorization: ["SETTINGS"],
                    roles: ["PLATFORM_ADMIN", "CONTROL_TOWER"],
                },
            },
            {
                path: "incoterms",
                name: "incoterms",
                component: () => import("@/views/settings/IncotermsPage.vue"),
                meta: {
                    authorization: ["SETTINGS"],
                    roles: ["PLATFORM_ADMIN", "CONTROL_TOWER"],
                },
            },
            {
                path: "users",
                name: "users",
                component: () => import("@/views/settings/UsersPage.vue"),
                meta: {
                    authorization: ["SETTINGS"],
                    roles: ["PLATFORM_ADMIN", "CONTROL_TOWER"],
                },
            },
            {
                path: "users/:uuid",
                name: "usersEdit",
                component: () => import("@/views/settings/UserEditPage.vue"),
                meta: {
                    authorization: ["SETTINGS"],
                    roles: ["PLATFORM_ADMIN", "CONTROL_TOWER"],
                },
            },
            {
                path: 'forbidden',
                name: '403',
                component: () => import('@/views/ForbiddenPage.vue'),
            },
        ],
    },
];

function checkAuth(auths) {
    const userPermissions = store.getters["account/getUserPermission"];
    for (let auth of auths) {
        let authorized = userPermissions[auth]
        if (authorized === null || authorized === false) { //The permission is DENY = true or null/false for other cases
            return true;
        }
    }
    return false;
}

function checkRole(roles) {
    const userRoles = store.getters["account/getUserRoles"];
    let authorized = false;
    roles.forEach((role) => {
        if (userRoles.includes(role)) authorized = true;
    });
    return authorized;
}

function getDrawerItems() {
    //Cerca il primo elemento, main
    let mainObject = null;

    for (let perm of permissions) {
        if (perm.name === "main") {
            mainObject = perm;
            break;
        }
    }

    if (mainObject === null) {
        console.error("Main item not found");
        return null;
    }
    let drawerItems = [];
    for (let perm of mainObject.children) {
        if (!perm.showInDrawer) {
            continue;
        }

        if (!checkPermission(perm)) {
            continue;
        }

        let drawer = {
            icon: perm.icon,
            text: perm.text,
            name: perm.name,
            key: perm.name,
            requiredTenantId: (perm.path ? perm.path.includes(":tenantId") : false),
            roles: (perm?.meta?.roles) ?? [],
            authorization: (perm?.meta?.authorization) ?? [],
        };
        drawerItems.push(drawer);
    }
    return drawerItems;
}

function getRouterItems() {
    return exploreChildren(permissions);
}


function checkPermission(path) {
    let meta = path?.meta;
    let authorized = !(meta?.roles) && !(meta?.authorization); //No Roles, not Authorization is public
    if (meta?.authorization) {
        authorized = checkAuth(meta.authorization); //Authorization, it means it can be accessible with explicit permissions
    }
    if (meta?.roles) {
        authorized = authorized || checkRole(meta.roles); //Roles, it means it can be accessible with Global Roles
    }
    return authorized;
}


function exploreChildren(childrens) {
    let routers = [];
    for (let perm of childrens) {
        if (!perm.path) {
            continue;
        }
        let router = {
            path: perm.path,
            name: perm.name,
            props: perm.props,
            component: perm.component,
            meta: perm.meta
        };
        if (perm.children) {
            router.children = exploreChildren(perm.children);
        }
        routers.push(router);
    }
    return routers;
}

function firstPageToLand(that) {
    let routes = getRouterItems();

    let mainRoutes = routes.filter((route) => route.name === "main")[0];

    for (let i = 0; i < mainRoutes.children.length; i++) {
        let route = mainRoutes.children[i];
        if (checkPermission(route)) {
            console.log("Route is " + route);
            let routePath = route.path;
            let params = {}
            if (routePath.includes(":tenantId")) {

                if (that.getTenant?.id == null) {
                    continue;
                }
                params = {
                    tenantId: that.getTenant?.id
                }
            }
            return that.$router.push({
                name: route.name, params: params
            });
        }
    }

    return that.$router.push({path: "/app"});
}
