<template>
  <v-chip
    variant="flat"
    :color="chipBackground"
    :style="{ border: `1px solid ${chipBorderColor}` }"
  >
    {{ status(item.status) }}
  </v-chip>
</template>

<script>
import purchaseOrderStatusEnum from "@/enums/purchaseOrderStatusEnum";

export default {
  props: {
    item: { type: Object, required: true },
  },
  data() {
    return {};
  },
  computed: {
    chipBackground() {
      switch (this.item.status) {
        case "BOOKED":
        case "TOTALY_BOOKED":
          return "#2F75B5";
        case "RECEIVED":
          return "#777777";
        case "CANCELLED":
          return "#D41C00";
        case "PROCESSING":
          return "#090C97";
        case "PARTIAL_BOOKED":
          return "#BADAF6";
        case "IN_TRANSIT":
          return "#3A7A0F";
        case "PARTIAL_IN_TRANSIT":
          return "#C2EDA4";
        case "CANCELLATION_REQUESTED":
          return "#FFC2C2";
        case "WAITING_QTY_CONFIRMATION":
          return "#FFE289";
        case "CLOSED":
          return "#1C8402";
        default:
          return "#CBD0DD";
      }
    },
    chipBorderColor() {
      switch (this.item.status) {
        case "BOOKED":
        case "TOTALY_BOOKED":
          return "#3976AE";
        case "RECEIVED":
          return "#E6E6E6";
        case "CANCELLED":
          return "#D41C00";
        case "PROCESSING":
          return "#090C97";
        case "PARTIAL_BOOKED":
          return "#B3D4F2";
        case "IN_TRANSIT":
          return "#3A7A0F";
        case "PARTIAL_IN_TRANSIT":
          return "#A9D08E";
        case "CANCELLATION_REQUESTED":
          return "#FF9999";
        case "WAITING_QTY_CONFIRMATION":
          return "#FFD966";
        case "CLOSED":
          return "#1C8402";
        default:
          return "#CBD0DD";
      }
    },
    status() {
      return (status) => {
        const statusObject = purchaseOrderStatusEnum.find(
          (item) => item.value === status
        );

        return statusObject?.title;
      };
    },
  },
};
</script>
